import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import MainLayout from "../components/MainLayout";

import * as styles from "./index.module.css";

const HomePage = () => {
  const data = useStaticQuery(graphql`
    query HomepageQuery {
      nodePage(id: { eq: "a62b5a53-02a2-53cb-b2d8-866ffa1fad19" }) {
        id
        title
        body {
          processed
        }
      }
    }
  `);

  const node = data.nodePage;
  return (
    <MainLayout>
      <div className={styles.container}>
        <div className={styles.heroImage}>
          <span className={styles.gradient}></span>
          <div
            className={styles.intro}
            dangerouslySetInnerHTML={{ __html: node.body.processed }}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default HomePage;
